const Paths = {
    home:"/",
    about:"/om/",
    tools:"/verktyg/",
    resources:"/resurser/",
    privacyPolicy:"/privacypolicy/",
    adPolicy:"/adPolicy/",
    projects:"/projekt/",
    bookTips:"/boktips/"
}

module.exports = {Paths}