import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoMdClose } from "react-icons/io";
import CookieConsent, { Cookies } from "react-cookie-consent";


function Navbar() {
  const [click, setClick] = useState(false);

  useEffect(() => {
    if (click) turnOffScroll();
  }, [click]);

  const handleClick = () => {
    if (click) turnOnScroll();
    else {
      turnOffScroll();
      setClick(true);
    }
  };

  handleClick;
  const closeMobileMenu = () => turnOnScroll();

  function turnOffScroll() {
    document.body.style.overflow = "hidden";
  }
  function turnOnScroll() {
    setClick(false);
    document.body.style.overflow = "auto";
  }

  function isHomePage() {
    if (typeof window !== "undefined") {
      if (window.location.pathname === "/" ) {
        return true;
      }
    }
    return false;
  }

  return (
    <>
      {typeof window === "undefined" ? (
        <></>
      ) : (
        <nav
          className={isHomePage() ? "navbar homepage-nav" : "navbar"}
          id="header"
          name="header"
        >
          <div className="wrapper navbar-container">
            <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
              <h3>✏️ Pluggdriven</h3>
            </Link>
            <div className="menu-icon" onClick={handleClick}>
              {click ? (
                <IoMdClose className="hamburger" />
              ) : (
                <GiHamburgerMenu className="hamburger" />
              )}
            </div>
            <ul className={click ? "nav-menu active" : "nav-menu"}>
              <li className="nav-item">
                <Link to="/quiz/" className="nav-links" onClick={closeMobileMenu}>
                  Quiz
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/matematik/" className="nav-links" onClick={closeMobileMenu}>
                  Matematik
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/kemi/" className="nav-links" onClick={closeMobileMenu}>
                  Kemi
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/fysik/" className="nav-links" onClick={closeMobileMenu}>
                  Fysik
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/blogg/"
                  className="nav-links"
                  onClick={closeMobileMenu}
                >
                  Alla artiklar
                </Link>
              </li>
            </ul>
          </div>
        </nav>
      )}

      <CookieConsent
        location="bottom"
        buttonText="Jag förstår"
        cookieName="cookie-consent"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        Den här sidan använder cookies för att erbjuda dig den bästa
        användarupplevelsen. Genom att fortsätta använda webbplatsen godkänner
        du att vi använder dem.
      </CookieConsent>
    </>
  );
}

export default Navbar;
