import React from "react";
import { Link } from "gatsby";
import { Paths } from "../../routes";
import "../Navbar/Navbar.scss";
import "./Footer.scss";
import "../../styles/socialmedia-icons.scss";

export default function Footer(props) {
  return (
    <div className={props.dark ? "footer darkFooter" : "footer"}>
      <div className="wrapper">
        <div className="upperFooter">
          <div className="footerSubSection">
            <div className="footerLeft">
              <h3>✏️ Pluggdriven</h3>
              <p>
              Lär dig mer om matte, kemi och fysik.
              </p>
            </div>
          </div>
          <div className="footerLinksContainer">
            <div className="footerSubSection">
              <h5>Innehåll</h5>
              <div className="linksFooter">
                <Link to={"/matematik/"}>Matematik</Link>
                <Link to={"/fysik/"}>Fysik</Link>
                <Link to={"/kemi/"}>Kemi</Link>
                <Link to={"/blogg/"}>Alla artiklar</Link>
              </div>
            </div>
            <div className="footerSubSection">
              <h5>Andra länkar</h5>
              <div className="linksFooter">
                <Link to={"/quiz/"}>Quiz</Link>
                <Link to={Paths.about}>Om oss</Link>
                <Link to={"/verktyg/"}>Hjälpmedel</Link>
                <Link to={Paths.privacyPolicy}>Privacy Policy</Link>
              </div>
            </div>
          
           
          </div>
        </div>
        <hr className="footerDivider" />
        <div className="footerEnding">
          <h6>© 2022 Pluggdriven.se</h6>
        </div>
      </div>
    </div>
  );
}
